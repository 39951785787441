export const environment = {
  production: false,
  DomainURL: "",
  DomainDestination: "",  
  APIURL: "https://api.quangnamginseng.com/api/v1/",
  APIRootURL: "https://api.quangnamginseng.com/",
  APIUploadURL: "https://upload.api.quangnamginseng.com/api/v1/",
  APIUploadRootURL: "https://upload.api.quangnamginseng.com/",
  LoginURL: "https://login.quangnamginseng.com/",
  Website: "https://quangnamginseng.com/",  
  IPRegistry: "https://ipv4.myexternalip.com/json",
  IPRegistryURL: "https://api.ipregistry.co/?key=tryout",
  LoadingFile: "loading.gif",
  DialogConfigWidth: "80%",
  DialogConfigWidth60: "60%",
  InitializationString: "",
  InitializationNumber: 0,  
  DanhMucQuocGiaIDVietNam: 237,  
  DanhMucUngDungID: 1,  
  DanhMucThanhVienID: 2,  
  DanhMucTinhThanhID: 3776,  
  DanhMucQuanHuyenID: 144286,  
  MapZoom: 11,
  Latitude: 15.1003,
  Longitude: 108.007,
  PageSize: 10,
  LineWidth: 3,
  MapPopupWidth: "600px",
  MapIconWidth: "30px",
  MapIcon: "30assets/image/logo_30_2024.pngpx",
  MaptilerAPIKey: "6iFTqps4QVACLERa26MA",
  TokenDefault: "d85df3cb-5688-4b1d-b120-50479b23d2a0",
  Token: "Token",
  TokenFCM: "TokenFCM",
  Download: "Download",
  MaSo: "00000000",
  Homepage: "Homepage",
  Login: "Login",
  Bearer: "Bearer null",
  ThanhVienToChucID: "ThanhVienToChucID",
  ThanhVienParentID: "ThanhVienParentID",
  ThanhVienID: "ThanhVienID",
  ThanhVienHoTen: "ThanhVienHoTen",
  ThanhVienTaiKhoan: "ThanhVienTaiKhoan",
  ThanhVienFileName: "ThanhVienFileName",
  UploadSuccess: "Upload thành công.",
  UploadNotSuccess: "Upload không thành công.",
  SaveSuccess: "Lưu thành công.",
  SaveNotSuccess: "Lưu không thành công.",
  DeleteConfirm: "Bạn muốn xóa dữ liệu này?",
  DeleteSuccess: "Xóa thành công.",
  DeleteNotSuccess: "Xóa không thành công.",
  LoginNotSuccess: "Đăng nhập không thành công.",
  UserNameNotExists: "Tài khoản chưa tồn tại.",
  UserNameExists: "Tài khoản đã tồn tại.",
  UserNameRequired: "UserName là bắt buộc.",
  ToChucExists: "Tổ chức đã tồn tại.",
  PageTitle: "SÂM NGỌC LINH QUẢNG NAM",
  PageTitleShort: "SÂM NGỌC LINH",
  PageDescription: "Sâm Ngọc Linh Quảng Nam",
  
  IPRegistryIP: "IPRegistryIP",
  IPRegistryDevice: "IPRegistryDevice",
  IPRegistryLongitude: "IPRegistryLongitude",
  IPRegistryLatitude: "IPRegistryIPLatitude",
  IPRegistryCountryName: "IPRegistryCountryName",
  IPRegistryRegionName: "IPRegistryRegionName",  
  IPRegistryCityName: "IPRegistryCityName",  

  TruyXuatNguonGocAPIURL: "https://api.cms.cft.carbonunit.net/api/v1/",
  TruyXuatNguonGocAPIRootURL: "https://api.cms.cft.carbonunit.net/",
  TruyXuatNguonGocAPIUploadURL: "https://api.upload.cft.carbonunit.net/api/v1/",
  TruyXuatNguonGocAPIUploadRootURL: "https://api.upload.cft.carbonunit.net/",
  TruyXuatNguonGocDanhMucUngDungToKen: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJKV1RTZXJ2aWNlQWNjZXNzVG9rZW4iLCJqdGkiOiIxNjQxNGY1Yi03ZTBlLTRlNjItYjBlNi0wMWI4OTE0NWU4NzUiLCJpYXQiOiI3LzE2LzIwMjQgOTo0NToxMCBBTSIsIklEIjoiMSIsImV4cCI6MTcyMzcxNTExMCwiaXNzIjoiSldUQXV0aGVudGljYXRpb25TZXJ2ZXIiLCJhdWQiOiJKV1RTZXJ2aWNlUG9zdG1hbkNsaWVudCJ9.hqAjkuEorF007ZlxNeovdpXPBMFHfRyIf3u1iGy9Zu4",
  TruyXuatNguonGocDanhMucUngDungID: "TruyXuatNguonGocDanhMucUngDungID",
  TruyXuatNguonGocToKen: "TruyXuatNguonGocToKen",
  TruyXuatNguonGocMaSo: "00000000",
  TruyXuatNguonGocToChucExists: "Tổ chức đã tồn tại.",

  BlockchainAPIURL: "https://cms.api.dgkchain.com/api/v1/",
  BlockchainAPIRootURL: "https://cms.api.dgkchain.com/",
  BlockchainAPIUploadURL: "https://upload.api.dgkchain.com/api/v1/",
  BlockchainAPIUploadRootURL: "https://upload.api.dgkchain.com/",
  BlockchainDanhMucUngDungToKen: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJKV1RTZXJ2aWNlQWNjZXNzVG9rZW4iLCJqdGkiOiIxNjQxNGY1Yi03ZTBlLTRlNjItYjBlNi0wMWI4OTE0NWU4NzUiLCJpYXQiOiI3LzE2LzIwMjQgOTo0NToxMCBBTSIsIklEIjoiMSIsImV4cCI6MTcyMzcxNTExMCwiaXNzIjoiSldUQXV0aGVudGljYXRpb25TZXJ2ZXIiLCJhdWQiOiJKV1RTZXJ2aWNlUG9zdG1hbkNsaWVudCJ9.hqAjkuEorF007ZlxNeovdpXPBMFHfRyIf3u1iGy9Zu4",
  BlockchainDanhMucUngDungID: "BlockchainDanhMucUngDungID",
  BlockchainToKen: "BlockchainToKen",
  BlockchainMaSo: "00000000",
  BlockchainToChucExists: "Tổ chức đã tồn tại.",
};
