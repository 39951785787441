<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-BanDo" data-bs-toggle="tab" (click)="Print()" style="cursor: pointer;"
                                    role="tab" aria-controls="BanDo" aria-selected="true">
                                    <span class="badge bg-success" style="font-size: 16px;"><i class="bi bi-printer"></i> Bản đồ dữ liệu tổng hợp huyện {{DanhMucQuanHuyenService.FormData.Name}}</span>
                                </a>
                            </li>                          
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="BanDo" role="tabpanel">
                                <div class="row">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <div class="map" #map style="height: 600px; width: 100%; position: relative;">
                                            <div style="z-index: 1000; position: absolute; top: 0;">
                                                <img src="assets/image/vungtrong.png" />
                                            </div>
                                            <div style="z-index: 1000; position: absolute; bottom: 0; height: 20px; width: 100%; background-color: #ffffff;">
                                            </div>
                                        </div>                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="DanhMucTinhThanhToaDoService.IsShowLoading"></app-loading>